import React from "react";
import { isPaidSelfhost } from "../constant/const";
import { useTranslation } from "react-i18next";

const PaidSelfhostHelp = ({ children, isOnlyhelpMsg }) => {
  const { t } = useTranslation();
  const isOnlyMsg = isOnlyhelpMsg || false;
  return isPaidSelfhost ? (
    <div
      className={`${
        !isOnlyMsg ? "bg-white shadow-md rounded-box my-2" : ""
      } px-3 pb-6 pt-6`}
    >
      {t("paid-selfhost-help")}
      <a href="mailto:billing@opensignlabs.com" className="cursor-pointer">
        billing@opensignlabs.com
      </a>
    </div>
  ) : (
    children
  );
};

export default PaidSelfhostHelp;
